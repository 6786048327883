import { Avatar } from 'antd'
import { myBaseURl } from '../../api/api'
import cls from './Avatar.module.scss'
import defaultAvatar from '../../assets/icons/defaultAvatar.webp'

interface Props {
    url?: string
    name?: boolean
    text?: string | number
}

const MyAvatar = ({ url, name, text }: Props) => {
    if (!url) {
        return (
            <img src={defaultAvatar} width={40} height={40} alt={'default'} />
        )
    }

    if (name) {
        return (
            <div style={{ width: '40px', height: '40px' }}>
                <Avatar
                    src={`${myBaseURl}${url}`}
                    size={'large'}
                    alt="avatar"
                />

                <div
                    style={{
                        position: 'absolute',
                        top: '40px',
                        marginLeft: '40px',
                        fontSize: '12px',
                        color: 'black'
                    }}
                >
                    {text}
                </div>
            </div>
        )
    }

    return (
        <div className={cls.gradient}>
            {/* <Avatar src={`${myBaseURl}${url}`} size={'large'} alt="avatar" /> */}
            <img src={`${myBaseURl}${url}`} loading="lazy" alt="avatar" />
        </div>
    )
}

export default MyAvatar
