import axios from "axios";
import { USER_TOKEN_KEY } from "../lib/const/localstorage";
import { tokenAvailability } from "../lib/helpers/tokenAvailability";




export const myBaseURl = process.env.REACT_APP_API_URL

const $api = axios.create({
    baseURL: myBaseURl,
})

$api.interceptors.request.use((config: any) => {
    if (tokenAvailability()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(USER_TOKEN_KEY)}`
    }
    return config;
})


// const clearLocalStorage = () => {
//     localStorage.removeItem(USER_TOKEN_KEY)
//     localStorage.removeItem(USER_REFRESH_TOKEN_KEY)
//     window.location.replace('/')
//     window.location.reload()

// }
// const refreshAccessToken = async () => {
//     try {
//         const response = await axios.post(`${myBaseURl}/api/auth/refresh-tokens`, {
//             refresh: localStorage.getItem(USER_REFRESH_TOKEN_KEY),
//         });
//         localStorage.setItem(USER_TOKEN_KEY, response.data.access);
//         return response.data.accessToken;
//     } catch (error) {
//         // clearLocalStorage()
//     }
// };


// $api.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true; // Помечаем, что мы уже пытались повторить запрос
//             const newAccessToken = await refreshAccessToken(); // Получаем новый access токен
//             axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`; // Обновляем токен в заголовках
//             originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`; // Обновляем токен в текущем запросе
//             return $api(originalRequest); // Повторяем запрос с новым токеном
//         }
//         return Promise.reject(error); // Передаем ошибку дальше, если условия не совпадают
//     }
// );


export default $api