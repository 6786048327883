import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SeasonsSchema } from '../types/SeasonsSchema';

const initialState: SeasonsSchema = {
    selectedSeasonId: ''
};

export const SeasonsSlice = createSlice({
    name: 'Seasons',
    initialState,
    reducers: {
        setSeasonId: (state, action: PayloadAction<string>) => {
            state.selectedSeasonId = action.payload
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(, (state) => {
    //             state.error = undefined;
    //             state.isLoading = true;
    //         })
    //         .addCase(, (state) => {
    //             state.isLoading = false;
    //         })
    //         .addCase(, (state, action) => {
    //             state.isLoading = false;
    //             state.error = action.payload;
    //         });
    // },
});

export const { actions: SeasonsActions } = SeasonsSlice;
export const { reducer: SeasonsReducer } = SeasonsSlice;