import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginByUsername } from '../services/LoginByUsername';
import { LoginSchema } from '../types/LoginSchema';

const initialState: LoginSchema = {
    isAuth: false,
    sessionStatus: true,
    roles: [],
    isLoading: false,
    username: '',
    password: '',
    usersOnlineCount: 0,
    videoPassword: '',
    videoIsAuth: false
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        setRoles: (state, action: PayloadAction<string[]>) => {
            state.roles = action.payload;
        },
        setUsersOnline: (state, action: PayloadAction<number>) => {
            state.usersOnlineCount = action.payload;
        },
        setSessionStatus: (state, action: PayloadAction<boolean>) => {
            state.sessionStatus = action.payload;
        },
        setVideoPassword: (state, action: PayloadAction<string>) => {
            state.videoPassword = action.payload;
        },
        setVideoError: (state, action: PayloadAction<boolean>) => {
            state.videoError = action.payload;
        },
        setVideoAuth: (state, action: PayloadAction<boolean>) => {
            state.videoIsAuth = action.payload;
        },



    },
    extraReducers: (builder) => {
        builder
            .addCase(loginByUsername.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(loginByUsername.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(loginByUsername.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions: loginActions } = loginSlice;
export const { reducer: loginReducer } = loginSlice;
