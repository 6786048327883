import { StateSchema } from "../../../../../app/providers/StoreProvider";

export const getLoginError = (state: StateSchema) => state?.login.error;
export const getSessionStatus = (state: StateSchema) => state?.login.sessionStatus;
export const getVideoPassword = (state: StateSchema) => state?.login.videoPassword;
export const getVideoError = (state: StateSchema) => state?.login.videoError;
export const getVideoIsAuth = (state: StateSchema) => state?.login.videoIsAuth;



