import cls from './CristianoGif.module.scss'

interface Props {
    [key: string]: boolean | undefined // Allows indexing with a string key
}

const gifs = {
    cristiano: 'https://giphy.com/embed/r1IMdmkhUcpzy',
    mbappe: 'https://giphy.com/embed/pzGIMbebiXFChVP29v',
    ronaldo: 'https://giphy.com/embed/ApD1UsWcVdJXmFLnCP',
    hulk: 'https://giphy.com/embed/XSc4Kkc5u2WZy',
    bmw: 'https://giphy.com/embed/f6R3ijvEeshaUd3cAQ',
    ronaldinho: 'https://giphy.com/embed/L3FInpXg6pGEGXPR0a',
    hacker: 'https://giphy.com/embed/B4dt6rXq6nABilHTYM'
}

export const CristianoGif = (props: Props) => {
    const activePropKey = Object.keys(props).find((key) => props[key])
    const gifUrl = gifs[activePropKey as keyof typeof gifs]

    if (gifUrl) {
        return (
            <iframe
                className={cls.cristiano}
                src={gifUrl}
                allowFullScreen
                title="myImg"
            />
        )
    }

    return <>none</>
}
