import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayersSchema } from '../types/PlayersSchema';
import { fetchPlayersData, fetchPlayersDataBySeason } from '../services';

const initialState: PlayersSchema = {
    data: [],
    dataBySeason: [],

};

export const PlayersSlice = createSlice({
    name: 'Players',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlayersData.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchPlayersData.fulfilled, (state, action) => {
                state.data = action.payload
                state.isLoading = false;
            })
            .addCase(fetchPlayersData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true
            })
            .addCase(fetchPlayersDataBySeason.pending, (state) => {
                state.seasonError = undefined;
                state.seasonIsLoading = true;
            })
            .addCase(fetchPlayersDataBySeason.fulfilled, (state, action) => {
                state.dataBySeason = action.payload
                state.seasonIsLoading = false;
            })
            .addCase(fetchPlayersDataBySeason.rejected, (state, action) => {
                state.seasonError = true;
                state.seasonIsLoading = false;
            })

    },
});

export const { actions: PlayersActions } = PlayersSlice;
export const { reducer: playersReducer } = PlayersSlice;