import { PlayerDetail } from '../../entities/PlayerDetail'
import { classNames } from '../../shared/lib/classNames/classNames'
import cls from './PlayerDetailPage.module.scss'

export const PlayerDetailPage = () => {
    return (
        <div className={classNames(cls.PlayerDetailPage, {}, [])}>
            <PlayerDetail />
        </div>
    )
}
