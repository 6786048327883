import { List } from 'antd'
import SimpleBar from 'simplebar-react'
import { Goal } from '../../../features/Players'
import { BallImages } from '../../lib/helpers/ballImages'
import { getCountGoalsByDate } from '../../lib/helpers/getCountGoalsByDate'
import cls from './PlayerGoalsSummByDate.module.scss'

interface Props {
    goals?: Goal[]
}

export const PlayerGoalsSummByDate = (props: Props) => {
    const { goals } = props

    const goalsDataByDate: { scoredDate: string; goals: number }[] | [] =
        getCountGoalsByDate(goals)

    return (
        <SimpleBar className={cls.PlayerGoalsSummByDate}>
            <List
                locale={{ emptyText: 'Нет голов' }}
                size="small"
                dataSource={goalsDataByDate}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <div style={{ width: '20px' }}>
                                    <h3>
                                        <b> {item.goals}</b>{' '}
                                    </h3>
                                </div>
                            }
                            title={
                                <>
                                    {' '}
                                    <BallImages number={item.goals} />
                                </>
                            }
                            description={item.scoredDate}
                        />
                    </List.Item>
                )}
            />
        </SimpleBar>
    )
}
