import { classNames } from '../../lib/classNames/classNames'
import cls from './MyMenu.module.scss'
import { Menu, MenuProps } from 'antd'

export interface MenuInfo {
    key: string
    keyPath: string[]
    /** @deprecated This will not support in future. You should avoid to use this */
    item: React.ReactInstance
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

interface Props {
    items: MenuProps['items']
    onClick: (info: MenuInfo) => void
    classname?: string
}
export const MyMenu = ({ items, onClick, classname }: Props) => {
    return (
        <Menu
            theme="dark"
            onClick={onClick}
            mode="horizontal"
            items={items}
            className={classNames(cls.menu, {}, [classname])}
        />
    )
}
