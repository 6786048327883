import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import api from '../../../../shared/api/api';
import { EditProps, PlayerNew } from '../types/PlayersSchema';

export const fetchPlayersData = createAsyncThunk<PlayerNew[], void, ThunkConfig<string>>(
    'players/fetchPlayersData',
    async (_, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<PlayerNew[]>('api/player/players');
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)


export const fetchPlayersDataBySeason = createAsyncThunk<PlayerNew[], string, ThunkConfig<string>>(
    'seasons/fetchPlayersDataBySeason',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<PlayerNew[]>(`/api/season/players/${id}`);
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)




export async function deletePlayerGoal(playerId: string | number, goalId: string | number): Promise<AxiosResponse<any>> {
    return api.delete(`/players/player/${playerId}/goals/${goalId}`)
}



export async function editPlayerGoals(playerId: string | number, goalId: string | number, object: EditProps): Promise<AxiosResponse<any>> {
    return api.put(`/players/player/${playerId}/goals/${goalId}`, {
        date: object.date,
        count: object.count
    })
}
