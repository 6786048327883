import { Modal } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { BestPlayerCard } from '../../../../entities/BestPlayer/ui/BestPlayerCard'
import { getFormattedDate } from '../../../../shared/lib/helpers/getFormattedDate'
import {
    getSummaryPromises,
    getTopScorers,
    PlayerGoalSummary
} from '../../../../shared/lib/helpers/summaryPromises'
import { useAppDispatch } from '../../../../shared/lib/hooks/useTheme/useAppDispatch'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { getPlayersdata } from '../../../Players'
import {
    getGameResultsGameDate,
    getGameResultsItemData
} from '../../model/selectors/getGetGameResultsNewdata'
import { fetchLastGameId } from '../../model/services'
import cls from './LastGameBestPlayer.module.scss'

export const LastGameBestPlayer = () => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = useState<boolean>()

    const { dataBySeason: Players } = useSelector(getPlayersdata)
    const { data, isLoading } = useSelector(getGameResultsItemData)
    const summaryPromisesSorted = getSummaryPromises(data.gameResults, Players)

    const openModal = () => {
        setOpen(true)
        setTimeout(() => {
            setOpen(false)
        }, 15000)
    }
    const closeModal = () => {
        setOpen(false)
    }
    const gameDateIso = useSelector(getGameResultsGameDate)

    const gameDate = useMemo(() => {
        return getFormattedDate(gameDateIso)
    }, [gameDateIso])

    useEffect(() => {
        dispatch(fetchLastGameId({ callback: openModal }))
    }, [dispatch])

    const bestPlayers: PlayerGoalSummary[] = getTopScorers(
        summaryPromisesSorted
    )

    return (
        <Modal
            destroyOnClose
            onCancel={closeModal}
            title={
                bestPlayers?.length > 1 ? (
                    <h3>Игроки матча: {gameDate}</h3>
                ) : (
                    <h3>Игрок матча: {gameDate}</h3>
                )
            }
            rootClassName={cls.modal}
            open={open}
            footer={false}
            closeIcon={true}
            forceRender
            style={{ padding: 0 }}
        >
            {isLoading ? (
                <Loader />
            ) : (
                bestPlayers?.map((item) => (
                    <BestPlayerCard
                        key={item.playerId}
                        bestPlayer={item}
                        tableSize={'small'}
                    />
                ))
            )}
        </Modal>
    )
}
