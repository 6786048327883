import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchGameResultButtonsData, fetchGameResultItem } from '../services';
import { GameResultItemResponse } from '../types/GameResultItem';
import { GameResultButtonsResponse, GetGameResultsNewSchema } from '../types/GetGameResultsNewSchema';

const initialState: GetGameResultsNewSchema = {
    buttonsData: { data: [] as GameResultButtonsResponse[] },
    gameResultItemData: { data: {} as GameResultItemResponse }
};



export const GetGameResultsNewSlice = createSlice({
    name: 'GetGameResultsNew',
    initialState,
    reducers: {
        template: (state, action: PayloadAction<string>) => {

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGameResultButtonsData.pending, (state) => {
                state.buttonsData.error = undefined
                state.buttonsData.isLoading = true

            })
            .addCase(fetchGameResultButtonsData.fulfilled, (state, action) => {
                state.buttonsData.isLoading = false;
                state.buttonsData.data = action.payload
            })
            .addCase(fetchGameResultButtonsData.rejected, (state, action) => {

                state.buttonsData.error = action.payload;
                state.buttonsData.isLoading = false
            })

            .addCase(fetchGameResultItem.pending, (state) => {
                state.gameResultItemData.isLoading = true;
                state.gameResultItemData.error = undefined;

            })
            .addCase(fetchGameResultItem.fulfilled, (state, action) => {
                state.gameResultItemData.data = action.payload
                state.gameResultItemData.isLoading = false
            })
            .addCase(fetchGameResultItem.rejected, (state, action) => {
                state.gameResultItemData.isLoading = false;
                state.gameResultItemData.error = action.payload;
            });
    },
});

export const { actions: GetGameResultsNewActions } = GetGameResultsNewSlice;
export const { reducer: GetGameResultsNewReducer } = GetGameResultsNewSlice;