import ballPng from '../../assets/icons/ball.png'
interface BallImagesProps {
    number: number
}

export function BallImages({ number }: BallImagesProps) {
    return (
        <>
            {Array.from({ length: number }, (_, index) => (
                <img
                    key={index}
                    src={ballPng}
                    alt="ball"
                    style={{ width: '12px' }}
                />
            ))}
        </>
    )
}
