import { useNavigate, useParams } from 'react-router-dom'
import { MyButton } from '../../../shared/ui/MyButton/MyButton'

import { UserOutlined } from '@ant-design/icons'
import {
    Card,
    Col,
    Descriptions,
    DescriptionsProps,
    List,
    Row,
    Skeleton,
    Space
} from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { Goal } from '../../../features/Players/model/types/PlayersSchema'
import { myBaseURl } from '../../../shared/api/api'
import defaultAvatar from '../../../shared/assets/icons/defaultAvatar.webp'
import { BallImages } from '../../../shared/lib/helpers/ballImages'
import { getFormattedDate } from '../../../shared/lib/helpers/getFormattedDate'
import { getLevelLabel } from '../../../shared/lib/helpers/getLevelLabel'
import { useAppDispatch } from '../../../shared/lib/hooks/useTheme/useAppDispatch'
import { CristianoGif } from '../../../shared/ui/CristianoGif/CristianoGif'
import { ErrorText } from '../../../shared/ui/ErrorText/ErrorText'
import { MbLogo } from '../../../shared/ui/MbLogo/MbLogo'
import { PlayerGoalsSummByDate } from '../../../shared/ui/PlayerGoalsSummByDate/PlayerGoalsSummByDate'
import { getPlayerDetailData } from '../model/selectors'
import { getPlayerDetailThunk } from '../model/service'
import { PlayerDetailResponse } from '../model/types'
import cls from './PlayerDetail.module.scss'

const commonColProps = {
    xs: 23,
    sm: 18,
    md: 14,
    lg: 14,
    xl: 8
}

function preloadImage(url: string) {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = url
        image.onload = resolve
        image.onerror = reject
    })
}

interface AvatarItemProps {
    imagePath: string
    myBaseURL: string
    defaultAvatar: string
    cls: string
}

export function AvatarItem({
    imagePath,
    myBaseURL,
    defaultAvatar,
    cls
}: AvatarItemProps) {
    useEffect(() => {
        if (imagePath) {
            preloadImage(`${myBaseURL}${imagePath}`)
                .then(() => console.log('Image loaded successfully'))
                .catch((error) => console.error('Image loading failed', error))
        }
    }, [imagePath, myBaseURL])

    return (
        <img
            src={imagePath ? `${myBaseURL}${imagePath}` : defaultAvatar}
            className={cls}
            alt="avatar"
        />
    )
}

export const getPlayerTitle = (data: PlayerDetailResponse | undefined) => {
    const playerConfig = playerConfigs[data?.id ? data.id : 0]
    if (playerConfig) {
        const { component: Component, props } = playerConfig
        return (
            <Space direction="horizontal">
                {data?.name} <Component {...props} />
            </Space>
        )
    }

    return data?.name
}

interface PlayerConfig {
    component: React.ComponentType<any>
    props: any
}

export const playerConfigs: Record<string, PlayerConfig> = {
    clvdr9mqz000f4onmvvoior8t: {
        component: MbLogo,
        props: { width: '50px' }
    },
    clvdr96va000d4onmz4uhos0w: {
        component: MbLogo,
        props: { width: '50px' }
    },
    clvdr8czg00094onmkj3lomwj: {
        component: CristianoGif,
        props: { mbappe: true }
    },
    clvdr7phr00064onm8kib2v9q: {
        component: CristianoGif,
        props: { ronaldo: true }
    },
    clvdr79wz00054onmoz8o36n0: {
        component: CristianoGif,
        props: { hulk: true }
    },
    clvdr725p00044onmu8zxso1z: {
        component: CristianoGif,
        props: { cristiano: true }
    },
    clvdr5ylh00014onmxeic0b0g: {
        component: CristianoGif,
        props: { bmw: true }
    },
    clvdr6bn200024onmk73v6khp: {
        component: CristianoGif,
        props: { ronaldinho: true }
    },
    clvdr8r83000b4onmvopurlr5: {
        component: CristianoGif,
        props: { hacker: true }
    }
}

export const PlayerDetail = () => {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const { data, isError, isLoading } = useSelector(getPlayerDetailData)

    const params = useParams<{
        playerId: string
        playerGeneratedLevel: string
    }>()

    const GoalsItem = ({ goals }: { goals?: Goal[] }) => (
        <Space direction="vertical" style={{ width: '100%' }}>
            <SimpleBar style={{ maxHeight: 250 }}>
                <List
                    locale={{ emptyText: 'Нет голов' }}
                    size="small"
                    dataSource={goals}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <div style={{ width: '20px' }}>
                                        {BallImages({ number: item.count })}
                                    </div>
                                }
                                title={
                                    <> {getFormattedDate(item.scoredDate)}</>
                                }
                                description={` ${item?.forTeam} vs ${item?.vsTeam}`}
                            />
                        </List.Item>
                    )}
                />
            </SimpleBar>
        </Space>
    )

    function preloadImage(url: string) {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.src = url
            image.onload = resolve
            image.onerror = reject
        })
    }

    useEffect(() => {
        if (data?.imagePath) {
            preloadImage(`${myBaseURl}${data?.imagePath}`)
                .then(() => console.log('Image loaded successfully'))
                .catch((error) => console.error('Image loading failed', error))
        }
        //eslint-disable-next-line
    }, [data?.imagePath, myBaseURl])

    const items: DescriptionsProps['items'] = [
        {
            key: '2',
            label: <UserOutlined />,
            children: (
                <AvatarItem
                    cls={cls.avatar}
                    defaultAvatar={defaultAvatar}
                    imagePath={data?.imagePath || ''}
                    myBaseURL={myBaseURl || ''}
                />
            )
        },

        // {
        //     key: '22',
        //     label: 'Никнейм',
        //     children: <b></b>
        // },
        // {
        //     key: '5',
        //     label: 'Дата рождения / Возраст',
        //     children: <b>{getFormattedDateIso(data?.data?.birthDate)}</b>
        // },
        {
            key: '3',
            label: 'Метки',
            children: <>{getLevelLabel(Number(params.playerGeneratedLevel))}</>
        },

        {
            key: '4',
            label: 'Голы по дате',
            children: <PlayerGoalsSummByDate goals={data?.goals} />,
            style: { padding: 4 }
        },
        {
            key: '5',
            label: 'Голы',
            children: <GoalsItem goals={data?.goals} />,
            style: { padding: 4 }
        }
    ]

    const itemsSkeleton: DescriptionsProps['items'] = [
        {
            key: '2',
            label: <UserOutlined />,
            children: (
                <AvatarItem
                    cls={cls.avatar}
                    defaultAvatar={defaultAvatar}
                    imagePath={data?.imagePath || ''}
                    myBaseURL={myBaseURl || ''}
                />
            )
        },
        {
            key: '3',
            label: 'Метки',
            children: <Skeleton.Button active />
        },

        {
            key: '4',
            label: 'Голы',
            children: (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Skeleton active />
                </Space>
            )
        }
    ]
    const getPlayerData = () => {
        if (params.playerId) {
            dispatch(getPlayerDetailThunk(params.playerId))
        }
    }

    useEffect(() => {
        getPlayerData()
        //eslint-disable-next-line
    }, [params.playerId])

    if (isError) {
        return <ErrorText />
    }

    console.log(`${myBaseURl}${data?.imagePath}`)
    return (
        <Row justify={'center'}>
            <Col {...commonColProps}>
                <Space
                    direction="vertical"
                    style={{ padding: 0, width: '100%' }}
                >
                    <Card>
                        <Descriptions
                            labelStyle={{ textAlign: 'center', width: '30px' }}
                            size="small"
                            bordered
                            title={getPlayerTitle(data)}
                            items={isLoading ? itemsSkeleton : items}
                            column={1}
                        />
                    </Card>

                    <MyButton
                        onClick={() => navigate('/')}
                        variant={'filled'}
                        size="l"
                    >
                        Назад
                    </MyButton>
                </Space>
            </Col>
        </Row>
    )
}
