import { List } from 'antd'
import { Divider, Popup, Space } from 'antd-mobile'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { getPlayerDetailPopupdata } from '../..'
import { myBaseURl } from '../../../../shared/api/api'
import defaultAvatar from '../../../../shared/assets/icons/defaultAvatar.webp'
import { BallImages } from '../../../../shared/lib/helpers/ballImages'
import { getLevelLabel } from '../../../../shared/lib/helpers/getLevelLabel'
import { useAppDispatch } from '../../../../shared/lib/hooks/useTheme/useAppDispatch'
import { PlayerGoalsSummByDate } from '../../../../shared/ui/PlayerGoalsSummByDate/PlayerGoalsSummByDate'
import { getPlayerDetailData } from '../../../PlayerDetail'
import { PlayerDetailActions } from '../../../PlayerDetail/model/slices/slice'
import { AvatarItem } from '../../../PlayerDetail/ui/PlayerDetail'
import { PlayerDetailPopupActions } from '../../model/slices/PlayerDetailPopupSlice'
import cls from './PlayerDetailPopup.module.scss'

const PlayerDetailPopup = memo(() => {
    const dispatch = useAppDispatch()
    const { data, isLoading } = useSelector(getPlayerDetailData)

    const { isVisible, playerRank } = useSelector(getPlayerDetailPopupdata)

    const setVisible = (a: boolean) => {
        dispatch(PlayerDetailPopupActions.setVisible(a))
        if (a === false) {
            dispatch(PlayerDetailActions.refresh())
        }
    }

    return (
        <Popup
            showCloseButton
            closeOnSwipe
            visible={isVisible}
            onClose={() => {
                setVisible(false)
            }}
            onMaskClick={() => {
                setVisible(false)
            }}
            bodyStyle={{
                height: '70%',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px'
            }}
        >
            <div className={playerRank >= 12 ? cls.gold : cls.wrapper}>
                <Space>
                    <AvatarItem
                        cls={cls.avatar}
                        defaultAvatar={defaultAvatar}
                        imagePath={data?.imagePath || ''}
                        myBaseURL={myBaseURl || ''}
                    />
                    <List header={<b>Имя: {data?.name}</b>}>
                        <List.Item>
                            <b>Гид</b>: {data?.goalPercentage}
                        </List.Item>
                        <List.Item>
                            <b>Голы</b>: {data?.goalsCount}{' '}
                            {BallImages({ number: 1 })}
                        </List.Item>
                        <List.Item>
                            <b>Метка</b>:{' '}
                            <>{getLevelLabel(Number(playerRank))}</>
                        </List.Item>
                    </List>
                </Space>
                <Divider />
                <PlayerGoalsSummByDate goals={data?.goals} />
                {isLoading && '...загрузка'}
            </div>
        </Popup>
    )
})

export default PlayerDetailPopup
