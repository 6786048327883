import { Tag } from 'antd'

import corona from '../../assets/icons/1198990.png'
import { PlayerNew } from '../../../features/Players/model/types/PlayersSchema'
import cls from './getLevelLabel.module.scss'

export function assignPlayerLevels(
    players: PlayerNew[],
    levels = 13
): PlayerNew[] {
    // Проверка, что входной массив не пустой
    if (!players || players.length === 0) {
        return []
    }

    // уберем игроков, у которых 0 голов

    const filteredZeroGoalPlayers = players.filter(
        (player) => player.goalsCount !== 0
    )

    // Убедимся, что goals всегда является массивом

    const maxGoals = Math.max(
        ...filteredZeroGoalPlayers.map((player) => player.goalsCount)
    )

    const minGoals = Math.min(
        ...filteredZeroGoalPlayers.map((player) => player.goalsCount)
    )

    const range = maxGoals - minGoals
    const step = range / levels

    return players.map((player) => {
        const playerGoalsCount = player.goalsCount
        const level = Math.ceil((playerGoalsCount - minGoals) / step) || 1 // Убедимся, что уровень не меньше 1

        return { ...player, generatedLevel: Math.min(level, levels) }
    })
}

const levelColors = [
    { max: 1, color: 'blue' },
    { max: 2, color: 'blue' },
    { max: 3, color: 'volcano' },
    { max: 4, color: 'orange' },
    { max: 5, color: 'yellow' },
    { max: 6, color: 'magenta' },
    { max: 7, color: 'green' },
    { max: 8, color: 'blue' },
    { max: 9, color: 'cyan' },
    { max: 10, color: 'pink' },
    { max: 11, color: 'gold' },
    { max: 13, color: 'red' },
    { max: 14, color: 'black' },
    { max: 15, color: 'gray' },
    { max: 16, color: 'yellow' },
    { max: 17, color: 'black' },
    { max: 18, color: 'red' },
    { max: Infinity, color: 'error' }
]

export const getColorForLevel = (level: number) => {
    for (let item of levelColors) {
        if (level < item.max) {
            return item.color
        }
    }
}

const GetTagWithColor = (text: string, level: number): JSX.Element => {
    return (
        <Tag color={getColorForLevel(level)} className={cls.tag}>
            {' '}
            {text}
        </Tag>
    )
}

export function getLevelLabel(level: number): React.ReactNode {
    switch (true) {
        case level >= 12:
            return <img width={25} height={25} src={corona} alt="corona" />

        case level >= 11:
            return GetTagWithColor('Легенда+', level)
        case level >= 10:
            return GetTagWithColor('Легенда', level)
        case level >= 9:
            return GetTagWithColor('Элита', level)
        case level >= 8:
            return GetTagWithColor('Профессионал', level)
        case level >= 7:
            return GetTagWithColor('Мастер', level)
        case level >= 6:
            return GetTagWithColor('Продвинутый', level)
        case level >= 5:
            return GetTagWithColor('Средний', level)
        case level >= 4:
            return GetTagWithColor('Любитель', level)
        case level >= 3:
            return GetTagWithColor('НовичОк', level)
        case level >= 2:
            return GetTagWithColor('Начинающий', level)
        case level >= 1:
            return GetTagWithColor('Без уровня', level)
        default:
            return `нет голов` // или какое-то другое значение по умолчанию
    }
}
