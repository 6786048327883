import { memo, useCallback } from 'react'
import cls from './LoginForm.module.scss'
import { useSelector } from 'react-redux'
import { loginActions } from '../model/slice/LoginSlice'

import { loginByUsername } from '../model/services/LoginByUsername'
import { useNavigate } from 'react-router-dom'
import { getLoginUsername } from '../model/selectors/getLoginUsername/getLoginUsername'
import {
    getLoginPassword,
    getUserAuth
} from '../model/selectors/getLoginPassword/getLoginPassword'
import { getLoginIsLoading } from '../model/selectors/getLoginIsLoading/getLoginIsLoading'
import { getLoginError } from '../model/selectors/getLoginError/getLoginError'
import { Button, Input, Row } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { useAppDispatch } from '../../../shared/lib/hooks/useTheme/useAppDispatch'
import useExitFromsite from '../../../shared/lib/hooks/useExit/exitFromsite'

export const LoginForm = memo(() => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isAuth = useSelector(getUserAuth)
    const { exit } = useExitFromsite()

    const username = useSelector(getLoginUsername)
    const password = useSelector(getLoginPassword)
    const isLoading = useSelector(getLoginIsLoading)
    const error = useSelector(getLoginError)

    const onChangeUsername = useCallback(
        (value: string) => {
            dispatch(loginActions.setUsername(value))
        },
        [dispatch]
    )

    const onChangePassword = useCallback(
        (value: string) => {
            dispatch(loginActions.setPassword(value))
        },
        [dispatch]
    )

    const onLoginClick = useCallback(() => {
        dispatch(loginByUsername({ password: password, name: username }))
        navigate('/')
    }, [dispatch, username, password, navigate])

    if (isAuth) {
        return <Button onClick={exit}>Выйти</Button>
    }

    return (
        <div className={cls.LoginForm}>
            <Typography>Авторизация:</Typography>
            <Input
                className={cls.input}
                placeholder="Логин"
                value={username}
                onChange={(e) => onChangeUsername(e.target.value)}
            />
            <Input
                type="password"
                className={cls.input}
                placeholder="Пароль"
                value={password}
                onChange={(e) => onChangePassword(e.target.value)}
            />
            <Row justify={'end'}>
                <Button onClick={onLoginClick} type="primary">
                    Войти
                </Button>
            </Row>
            {isLoading && '...загрузка'}
            {error && (
                <div style={{ color: 'red' }}>
                    Неправильный логин или пароль
                </div>
            )}
        </div>
    )
})
