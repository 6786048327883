import { MenuProps } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserAuth } from '../../features/AuthByUsername'
import { Seasons } from '../../features/Seasons'
import { lastUpdateDate } from '../../lastUpdate'
import {
    getRouteOverAllRating,
    getRouteVideosPage
} from '../../shared/lib/const/router'
import useExitFromsite from '../../shared/lib/hooks/useExit/exitFromsite'
import { MyMenu } from '../../shared/ui/Menu/MyMenu'
import cls from './Header.module.scss'

export const Header = () => {
    const isAuth = useSelector(getUserAuth)
    const { exit } = useExitFromsite()
    const navigate = useNavigate()

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'exit') {
            return exit()
        }
        navigate(e.key)
    }

    const items: MenuProps['items'] = [
        {
            label: <Seasons />,
            key: '/season1',
            disabled: true
        },
        {
            label: 'Рейтинг',
            key: '/'
        },
        {
            label: 'Результаты',
            key: '/gameResults'
        },
        {
            label: 'Команды',
            key: '/teams'
        },
        {
            label: 'Общий рейтинг',
            key: getRouteOverAllRating()
        },
        {
            label: 'Видео',
            key: getRouteVideosPage()
        },
        {
            label: 'О сайте',
            key: '/about'
        },

        {
            label: `${isAuth ? 'Выйти' : 'Войти'}`,
            key: `${isAuth ? 'exit' : '/login'}`
        },

        {
            label: `Дата обновления ${lastUpdateDate}`,
            disabled: true,
            key: ''
        }
        // {
        //     label: `Графики`,
        //     disabled: false,
        //     key: getRoutePlayerCharts()
        // }
    ]

    return (
        <header className={cls.header} style={{ minWidth: 0, flex: 'auto' }}>
            <MyMenu items={items} onClick={onClick} />
            {/* <MbLogo width="20px" /> */}
        </header>
    )
}
