import { loginActions } from "../../../../features/AuthByUsername/model/slice/LoginSlice";
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from "../../const/localstorage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../useTheme/useAppDispatch";




const useExitFromsite = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const exit = () => {

        localStorage.removeItem(USER_TOKEN_KEY)
        localStorage.removeItem(USER_REFRESH_TOKEN_KEY)
        dispatch(loginActions.setIsAuth(false))
        localStorage.removeItem('token')
        dispatch(loginActions.setRoles(['']))
        navigate('/login')
    }

    return { exit }
}

export default useExitFromsite