import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../types/LoginSchema';
import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { message } from 'antd';
import { loginActions } from '../slice/LoginSlice';
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from '../../../../shared/lib/const/localstorage';


interface LoginByUsernameProps {
    name: string;
    password: string;
}


export const loginByUsername = createAsyncThunk<User, LoginByUsernameProps, ThunkConfig<string>>(
    'login/loginByUsername',
    async (authData, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.post<User>('api/auth/login', authData);
            localStorage.setItem(USER_TOKEN_KEY, response.data.accessToken)
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, response.data.refreshToken)
            message.success('Успешно авторизованы!')
            dispatch(loginActions.setIsAuth(true))
            return response.data;

        } catch (e: any) {
            loginActions.setIsAuth(false)
            message.error('Ошибка при авторизации')
            return rejectWithValue('error');

        }
    },
);
