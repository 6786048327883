import { createSlice } from '@reduxjs/toolkit';
import { getPlayerDetailThunk } from '../service';
import { PlayerDetailSchema } from '../types';

const initialState: PlayerDetailSchema = {

};

export const PlayerDetailSlice = createSlice({
    name: 'test1',
    initialState,
    reducers: {
        refresh: (state,) => {
            state.data = undefined
            state.isError = false
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPlayerDetailThunk.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(getPlayerDetailThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload
            })
            .addCase(getPlayerDetailThunk.rejected, (state) => {
                state.isError = true
                state.isLoading = false
            });
    },
});

export const { actions: PlayerDetailActions } = PlayerDetailSlice;
export const { reducer: PlayerDetailReducer } = PlayerDetailSlice;
