import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddGameResultSchema } from '../types';

const initialState: AddGameResultSchema = {
    gameId: '',
    isEditing: false,
    currentGameId: ''
};

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        setIsEditing: (state, action: PayloadAction<boolean>) => {
            state.isEditing = action.payload
        },
        setGameId: (state, action: PayloadAction<string>) => {
            state.gameId = action.payload
        },
        setCurrentGameId: (state, action: PayloadAction<string>) => {
            state.currentGameId = action.payload
        },
    },

});

export const { actions: addGameResultActions } = testSlice;
export const { reducer: addGameResultReducer } = testSlice;