import { ThunkConfig } from '../../../../app/providers/StoreProvider/config/StateSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GameResultItemResponse, LastGameIDResponse } from '../types/GameResultItem';


export const fetchGameResultButtonsData = createAsyncThunk<any, string, ThunkConfig<string>>(
    'gameeResult/fetchGameResultButtonsData',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<any>(`/api/season/${id}`);
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)

interface FetchLastGameId {
    callback: () => void

}

export const fetchLastGameId = createAsyncThunk<LastGameIDResponse, FetchLastGameId, ThunkConfig<string>>(
    'lastGameBestPlayer/fetchLastGameId',
    async ({ callback }, thunkApi) => {
        const { extra, dispatch, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<LastGameIDResponse>('/api/game-day/last');
            if (!response.data) {
                throw new Error();
            }
            dispatch(fetchGameResultItem(response.data.id))



            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        } finally {

            callback()
        }
    },
)



export const fetchGameResultItem = createAsyncThunk<GameResultItemResponse, string | number, ThunkConfig<string>>(
    'gameeResult/fetchGameResultItem',
    async (id, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi
        try {
            const response = await extra.api.get<GameResultItemResponse>(`/api/game-day/${id}`);
            if (!response.data) {
                throw new Error();
            }
            return response.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
)




