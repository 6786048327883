import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayerDetailPopupSchema } from '../types/PlayerDetailPopupSchema';

const initialState: PlayerDetailPopupSchema = {
    isVisible: false,
    playerRank: 0
};

export const PlayerDetailPopupSlice = createSlice({
    name: 'PlayerDetailPopup',
    initialState,
    reducers: {
        setVisible: (state, action: PayloadAction<boolean>) => {
            state.isVisible = action.payload
        },
        setPlayerRank: (state, action: PayloadAction<number>) => {
            state.playerRank = action.payload
        }
    },

});

export const { actions: PlayerDetailPopupActions } = PlayerDetailPopupSlice;
export const { reducer: PlayerDetailPopupReducer } = PlayerDetailPopupSlice;