import ReactDOM from 'react-dom/client'

import { App } from './app/App'
import { HashRouter } from 'react-router-dom'
import { ErrorBoundary } from './app/providers/ErrorBoundary'
import { StoreProvider } from './app/providers/StoreProvider'

import { ThemeProvider } from './app/providers/ThemeProvider'

import { ConfigProvider } from 'antd'
import './shared/config/i18n/i18n'
import './app/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <HashRouter>
        <StoreProvider>
            <ErrorBoundary>
                <ThemeProvider>
                    <ConfigProvider
                        theme={{
                            token: {
                                fontFamily: 'SFProDisplay'
                            },
                            components: {
                                Select: {
                                    controlOutline: 'none',
                                    colorBgMask: 'blue',
                                    colorBgLayout: 'red',
                                    colorBgContainer: 'none',
                                    colorBgElevated: 'black',
                                    colorBgTextHover: 'red',
                                    colorText: 'white',
                                    colorBorder: 'none',
                                    colorBgBase: 'red',
                                    optionSelectedBg: 'none',
                                    optionSelectedFontWeight: '500'
                                },
                                Carousel: {
                                    dotActiveWidth: 20,
                                    dotHeight: 10,
                                    dotWidth: 10
                                },
                                Menu: {
                                    borderRadius: 10,
                                    lineHeightSM: 30,
                                    horizontalLineHeight: 4,
                                    fontSizeSM: 10,
                                    iconSize: 12
                                },
                                Table: {
                                    cellFontSizeSM: 10,
                                    cellPaddingBlockSM: 3
                                },
                                Descriptions: {
                                    fontSizeSM: 11
                                }
                            }
                        }}
                    >
                        <App />
                    </ConfigProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </StoreProvider>
    </HashRouter>
)
