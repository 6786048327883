import { configureStore } from '@reduxjs/toolkit';
import { StateSchema } from './StateSchema';
import { counterReducer } from '../../../../entities/Counter/';
import $api from '../../../../shared/api/api';
import { playersReducer } from '../../../../features/Players/model/slices/PlayersSlice';
import { loginReducer } from '../../../../features/AuthByUsername/model/slice/LoginSlice';
import { GetGameResultsNewReducer } from '../../../../features/GetGameResultsNew/model/slices/GetGameResultsNewSlice';
import { addGameResultReducer } from '../../../../features/AddGameResult/slices/testSlice';
import { SeasonsReducer } from '../../../../features/Seasons/model/slices/SeasonsSlice';
import { PlayerDetailPopupReducer } from '../../../../entities/PlayerDetailPopup/model/slices/PlayerDetailPopupSlice';
import { PlayerDetailReducer } from '../../../../entities/PlayerDetail/model/slices/slice';

export function createReduxStore(initialState?: StateSchema) {

    return configureStore({
        reducer: {
            counter: counterReducer,
            playersData: playersReducer,
            login: loginReducer,
            gameResultsNew: GetGameResultsNewReducer,
            addGameResult: addGameResultReducer,
            seasons: SeasonsReducer,
            playerDetailIsvisble: PlayerDetailPopupReducer,
            playerDetail: PlayerDetailReducer

        },
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    api: $api,
                }
            },
        })
    });
}


export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];