import { Affix } from 'antd'
import { Suspense, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loginActions } from '../features/AuthByUsername/model/slice/LoginSlice'
import { fetchPlayersDataBySeason } from '../features/Players/model/services'
import { getSeasonsdata } from '../features/Seasons/model/selectors/getSeasonsdata'
import $api from '../shared/api/api'
import { classNames } from '../shared/lib/classNames/classNames'
import { USER_REFRESH_TOKEN_KEY } from '../shared/lib/const/localstorage'
import { tokenAvailability } from '../shared/lib/helpers/tokenAvailability'
import { useAppDispatch } from '../shared/lib/hooks/useTheme/useAppDispatch'
import { Loader } from '../shared/ui/Loader/Loader'
import Header from '../widgets/Header'
import { Theme, useTheme } from './providers/ThemeProvider'
import { AppRouter } from './providers/router'
import { LastGameBestPlayer } from '../features/GetGameResultsNew/ui/LastGameBestPlayer/LastGameBestPlayer'

export const App = () => {
    //ts

    //test2
    const { theme } = useTheme()
    const dispatch = useAppDispatch()

    const { selectedSeasonId } = useSelector(getSeasonsdata)

    const fetchPlayersDataNew = useCallback(() => {
        if (selectedSeasonId)
            dispatch(fetchPlayersDataBySeason(selectedSeasonId))
    }, [dispatch, selectedSeasonId])

    async function checkToken() {
        if (tokenAvailability())
            try {
                await $api.post('/api/auth/refresh-tokens', {
                    refreshToken: localStorage.getItem(USER_REFRESH_TOKEN_KEY)
                })
                dispatch(loginActions.setIsAuth(true))
            } catch (e) {}
    }

    useEffect(() => {
        checkToken()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchPlayersDataNew()
        // eslint-disable-next-line
    }, [selectedSeasonId])
    //
    //
    return (
        <div className={classNames('app', {}, [theme || Theme.LIGHT])}>
            <LastGameBestPlayer />
            <Affix>
                <Header />
            </Affix>
            <Suspense fallback={<Loader />}>
                <AppRouter />
            </Suspense>
        </div>
    )
}
