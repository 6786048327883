import { RouteProps } from 'react-router-dom'
import { PlayersRatingPage } from '../../../pages/PlayersRatingPage'
import { UserRoles } from '../../lib/types'
import {
    AppRoutes,
    getRouteAbout,
    getRouteGameResults,
    getRouteLogin,
    getRouteMainPage,
    getRouteOverAllRating,
    getRoutePlayerCharts,
    getRoutePlayerDetail,
    getRouteTeams,
    getRouteVideosPage
} from '../../lib/const/router'
import { PlayerDetailPage } from '../../../pages/PlayerDetailPage/PlayerDetailPage'
import { AboutPage } from '../../../pages/AboutPage'
import { LoginPage } from '../../../pages/LoginPage'
import { GameResultPageNew } from '../../../pages/GameResultPageNew'
import { TeamsPage } from '../../../pages/TeamsPage'
import { PlayersRatingAllSeasonsPage } from '../../../pages/PlayersRatingAllSeasonsPage'
import { VideosPage } from '../../../pages/VideosPage'
import { PlayerChartByGoalPage } from '../../../pages/PlayerChartByGoalPage'

export type AppRoutesProps = RouteProps & {
    authOnly?: boolean
    roles?: UserRoles[]
    preQualification?: boolean
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.PLAYERS_RATING]: {
        path: getRouteMainPage(),
        element: <PlayersRatingPage />
    },
    [AppRoutes.PLAYER_DETAIL]: {
        path: getRoutePlayerDetail(':playerId', ':playerGeneratedLevel'),
        element: <PlayerDetailPage />,
        authOnly: false
    },
    [AppRoutes.GAME_RESULTS]: {
        path: getRouteGameResults(),
        element: <GameResultPageNew />,
        authOnly: false
    },
    [AppRoutes.ABOUT]: {
        path: getRouteAbout(),
        element: <AboutPage />
    },
    [AppRoutes.LOGIN]: {
        path: getRouteLogin(),
        element: <LoginPage />
    },
    [AppRoutes.TEAMS]: {
        path: getRouteTeams(),
        element: <TeamsPage />
    },
    [AppRoutes.OVERALL_RATING]: {
        path: getRouteOverAllRating(),
        element: <PlayersRatingAllSeasonsPage />
    },
    [AppRoutes.VIDEO]: {
        path: getRouteVideosPage(),
        element: <VideosPage />
    },
    [AppRoutes.CHARTS]: {
        path: getRoutePlayerCharts(),
        element: <PlayerChartByGoalPage />
    }
}
