import { GameResult } from "../../../features/GetGameResultsNew/model/types/GameResultItem"
import { PlayerNew } from "../../../features/Players/model/types/PlayersSchema"
import { secredplayerId } from "../const/secretPlayerId"

export interface PlayerGoalSummary {
    playerId: string
    name: string
    goals: number
    imagePath: string
    gid: string
}

export const getSummaryPromises = (GameResultData: GameResult[], Players: PlayerNew[]) => {

    const calculatePlayerGoals = (
        gameResults: GameResult[]
    ): Record<string, number> => {
        const playerGoals: Record<string, number> = {}
        gameResults?.forEach((game) => {
            ;[...game.goals1, ...game.goals2]?.forEach(({ playerId, count }) => {
                playerGoals[playerId] = (playerGoals[playerId] || 0) + count
            })
        })

        return playerGoals
    }


    const getPlayerNameById = (playerId: string, Players: PlayerNew[]): PlayerNew => {
        const playerItem =
            Players.find((player) => player.id === playerId) || ({} as PlayerNew)
        return playerItem
    }

    const summaryPromises: PlayerGoalSummary[] = Object.entries(
        calculatePlayerGoals(
            GameResultData
                ? GameResultData
                : [] as GameResult[]
        )
    ).map(([playerId, goals]): PlayerGoalSummary => {
        const player: PlayerNew = getPlayerNameById(playerId, Players)
        return {
            playerId,
            goals,
            name: player.name,
            imagePath: player.imagePath,
            gid: player.goalPercentage
        }
    })

    const summaryPromisesSorted = summaryPromises.sort((a, b) => b.goals - a.goals)
    return summaryPromisesSorted
}

export const getTopScorers = (players: PlayerGoalSummary[]): PlayerGoalSummary[] => {
    if (players.length === 0) return [];

    const withoutSecret = players.filter((player) => player.playerId !== secredplayerId)

    // Находим максимальное количество голов
    const maxGoals = Math.max(...withoutSecret.map(player => player.goals));

    // Находим всех игроков с максимальным количеством голов
    const topScorers = withoutSecret.filter(player => player.goals === maxGoals);

    // Находим минимальное количество гид
    const minGid = Math.min(...topScorers.map(player => Number(player.gid)));

    // Находим всех игроков с максимальным количеством голов
    const minGidPlayers = topScorers.filter(player => Number(player.gid) === minGid);



    return minGidPlayers;
};

