import { Goal } from "../../../features/Players";
import { getFormattedDate } from "./getFormattedDate";


type GoalsByDate = {
    [date: string]: number
}

export function getCountGoalsByDate(
    goalsArray?: Goal[]
): { scoredDate: string; goals: number }[] | [] {
    if (!goalsArray) {
        return []
    }
    const goalsByDate: GoalsByDate = goalsArray.reduce(
        (acc: GoalsByDate, goal: Goal) => {
            const date = goal.scoredDate
            if (!acc[getFormattedDate(date)]) {
                acc[getFormattedDate(date)] = 0
            }
            acc[getFormattedDate(date)] += goal.count
            return acc
        },
        {}
    )

    return Object.keys(goalsByDate).map((date) => ({
        scoredDate: date,
        goals: goalsByDate[date]
    }))
}